import React, { Component } from 'react';
import { connect } from 'react-redux';
import clone from 'clone';
import { Link } from 'react-router-dom';
import { Layout } from 'antd';
import MenuOptions from './options';
import Scrollbars from '@isomorphic/shared/isomorphic/components/utility/customScrollBar.js';
import Menu from '@isomorphic/shared/isomorphic/components/uielements/menu';
import IntlMessages from '@isomorphic/shared/isomorphic/components/utility/intlMessages';
import SidebarWrapper from './sidebar.style';
import appActions from '../../redux/app/actions';
import Logo from '@isomorphic/shared/isomorphic/components/utility/logo';
import moment from 'moment';
import {
  CryptoIcon,
  OverviewIcon,
  AnalysisIcon,
  FlowIcon,
  BacktesterIcon,
  SmartEntryIcon,
  DeepStrikeIcon,
  RadarIcon,
  AggreflowIcon,
  EarningsIcon,
  OptionsIcon,
  DboxIcon,
  PerformanceIcon,
  InsightsIcon,
  WathlistsIcon,
  AifeedIcon,
  MoneylineIcon,
  ZdteIcon,
  PJournalIcon,
  CalendarIcon,
  ChatIcon,
  ScreenIcon,
  AlertsIcon,
  RiskRewardIcon,
  FlowProphetIcon,
  TradeAnalysisIcon,
  AlertIcon,
  HelpIcon,
  InsidersIcon,
  SignalsIcon,
  StudyIcon,
  PortfolioIcon,
  ChartSetupsIcon,
  ExposureIcon,
  DarkpoolIcon,
  ReplayIcon,
  NewsIcon,
  JournalIcon,
  StockIcon,
} from './icons';
// import Logo from '../../shared/isomorphic/components/utility/logo';
//import Tooltip from '@isomorphic/shared/isomorphic/components/uielements/tooltip';
const SubMenu = Menu.SubMenu;
const { Sider } = Layout;
const newt = {
  marginLeft: '3px',
  backgroundColor: '#060d13',
  paddingLeft: '.25rem',
  paddingRight: '.25rem',
  paddingTop: '.125rem',
  paddingBottom: '.125rem',
  fontSize: '10px',
  color: '#7252e6',
};

//const MenuItemGroup = Menu.ItemGroup;
const {
  toggleOpenDrawer,
  changeOpenKeys,
  changeCurrent,
  toggleCollapsed,
} = appActions;
let rr = 0;
let isPre = false;
let registerUser = '';
let daysTillExpiry = 0;

if (localStorage.getItem('pl')) {
  let roles = JSON.parse(localStorage.getItem('pl')).roles;
  rr = roles && Array.isArray(roles) ? roles[0] : null;
  isPre = rr == 'tradeuipro' ? true : false;
  registerUser = JSON.parse(localStorage.getItem('pl')).user_registered;
  if (registerUser !== '') {
    daysTillExpiry = moment(registerUser)
      .add(14, 'd')
      .diff(moment(), 'd');
  }
}

const stripTrailingSlash = str => {
  if (str.substr(-1) === '/') {
    return str.substr(0, str.length - 1);
  }
  return str;
};
class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.handleMenuClick = this.handleMenuClick.bind(this);
    this.onOpenChange = this.onOpenChange.bind(this);
  }

  componentDidMount() {
    this.upgrade = 'hide';
    this.v = 0;
    if (!localStorage.getItem('v')) {
      localStorage.setItem('v', this.v);
    } else if (
      localStorage.getItem('v') &&
      parseFloat(localStorage.getItem('v')) !== this.v
    ) {
    }
  }
  lo() {
    localStorage.removeItem('pl');
    localStorage.removeItem('id_token');
    document.location.href = '/signin';
  }
  handleMenuClick(e) {
    const { app, changeOpenKeys } = this.props;
    this.props.changeCurrent([e.key]);
    changeOpenKeys(e.key);
  }
  handleClick(e) {
    e.preventDefault();
    this.props.toggleCollapsed();
  }
  getSubItemIcons(icon, collapsed) {
    let styleicon = {};
    if (collapsed) styleicon = { maxWidth: '30px!important' };

    switch (icon) {
      case 'backtester':
        return (
          <>
            <BacktesterIcon />
          </>
        );
      case 'radar':
        return (
          <>
            <SmartEntryIcon />
          </>
        );
      case ' deepstrike':
        return (
          <>
            <DeepStrikeIcon />
          </>
        );
      case 'smartentry':
        return (
          <>
            <SmartEntryIcon />
          </>
        );
      case 'news':
        return (
          <>
            <NewsIcon />
          </>
        );
      case 'jperformance':
        return (
          <>
            <PJournalIcon />
          </>
        );
      case 'jreplay':
        return (
          <>
            <ReplayIcon />
          </>
        );
      case 'jreports':
        return (
          <>
            <JournalIcon />
          </>
        );
      case 'calendar':
        return (
          <>
            <CalendarIcon />
          </>
        );
      case 'performance':
        return (
          <>
            <PJournalIcon />
          </>
        );
      case 'crypto':
        return (
          <>
            <CryptoIcon />
          </>
        );
      case 'overview':
        return (
          <>
            <OverviewIcon />
          </>
        );
      case 'analysis':
        return (
          <>
            <AnalysisIcon />
          </>
        );
      case 'flow':
        return (
          <>
            <FlowIcon />
          </>
        );
      case 'Backtester':
        return (
          <>
            {' '}
            <BacktesterIcon />
          </>
        );
      case 'aggreflow':
        return (
          <>
            <AggreflowIcon />
          </>
        );
      case 'earnings':
        return (
          <>
            <EarningsIcon />
          </>
        );
      case 'options':
        return (
          <>
            <OptionsIcon />
          </>
        );

      case 'dbox':
        return (
          <>
            <DboxIcon />
          </>
        );
      case 'performance':
        return (
          <>
            <PerformanceIcon />
          </>
        );
      case 'screener':
        return (
          <>
            <ScreenIcon />
          </>
        );
      case 'study':
        return (
          <>
            <StudyIcon />
          </>
        );
      case 'signals':
        return (
          <>
            <SignalsIcon />
          </>
        );
      case 'insiders':
        return (
          <>
            <InsidersIcon />
          </>
        );

      case 'ticker':
        return (
          <>
            <StockIcon />
          </>
        );
      case 'stock':
        return (
          <>
            <StockIcon />
          </>
        );
      case 'alertsico':
        return (
          <>
            <AlertIcon />
          </>
        );
      case 'tradeanalysis':
        return (
          <>
            <TradeAnalysisIcon />
          </>
        );
      case 'flowprophet':
        return (
          <>
            <FlowProphetIcon />
          </>
        );
      case 'alerts':
        return (
          <>
            <AlertsIcon />
          </>
        );

      case 'riskreward':
        return (
          <>
            <RiskRewardIcon />
          </>
        );
      case 'guide':
        return <HelpIcon />;

      case 'insights':
        return <InsightsIcon />;
      case 'watchlists':
        return (
          <>
            <WathlistsIcon />
          </>
        );
      case 'aifeed':
        return (
          <>
            <AifeedIcon />
          </>
        );
      case 'moneyline':
        return (
          <>
            <MoneylineIcon />
          </>
        );

      case 'zdte':
        return (
          <>
            <ZdteIcon />
          </>
        );

      case 'chaticon':
        return (
          <>
            <ChatIcon />
          </>
        );
      case 'portfolio':
        return (
          <>
            <PortfolioIcon />
          </>
        );
      case 'exposure':
        return (
          <>
            {' '}
            <ExposureIcon />
          </>
        );
      case 'darkpool':
        return (
          <>
            <DarkpoolIcon />
          </>
        );
      case 'chart-setups':
        return (
          <>
            <ChartSetupsIcon />
          </>
        );
    }
  }
  onOpenChange(newOpenKeys) {
    const { app, changeOpenKeys } = this.props;
    localStorage.setItem('sidebarOpenKeys', JSON.stringify(newOpenKeys));

    const latestOpenKey = newOpenKeys.find(
      key => !(app.openKeys && app.openKeys.indexOf(key) > -1)
    );
    const latestCloseKey =
      app.openKeys &&
      Array.isArray(app.openKeys) &&
      app.openKeys.find(key => !(newOpenKeys.indexOf(key) > -1));

    let nextOpenKeys = [];
    if (latestOpenKey) {
      nextOpenKeys = this.getAncestorKeys(latestOpenKey).concat(latestOpenKey);
    }
    if (latestCloseKey) {
      nextOpenKeys = this.getAncestorKeys(latestCloseKey);
    }
    changeOpenKeys(newOpenKeys);
  }

  onOpenChange_old(newOpenKeys) {
    const { app, changeOpenKeys } = this.props;
    localStorage.setItem('sidebarOpenKeys', JSON.stringify(newOpenKeys));

    const latestOpenKey = newOpenKeys.find(
      key => !(app.openKeys.indexOf(key) > -1)
    );
    const latestCloseKey = app.openKeys.find(
      key => !(newOpenKeys.indexOf(key) > -1)
    );
    let nextOpenKeys = [];
    if (latestOpenKey) {
      nextOpenKeys = this.getAncestorKeys(latestOpenKey).concat(latestOpenKey);
    }
    if (latestCloseKey) {
      nextOpenKeys = this.getAncestorKeys(latestCloseKey);
    }
    //changeOpenKeys(nextOpenKeys);
    changeOpenKeys(newOpenKeys);
  }

  
  getOpenedSub() {
    const isFirstLoad = !localStorage.getItem('hasLoadedBefore');

    const storedOpenKeys = localStorage.getItem('sidebarOpenKeys');
    if (storedOpenKeys) {
      return JSON.parse(storedOpenKeys);
    }else if (isFirstLoad) {
      // Set a flag to indicate the app has been loaded before
      localStorage.setItem('hasLoadedBefore', 'true');
      localStorage.setItem('sidebarOpenKeys', JSON.stringify([[],"backtesting","trading","stock","options","markets"]));
      // Return all main menu keys for first load only
      return [
        'markets',
        'options', 
        'stock',
        'trading',
        'ttools',
        'journal',
        'backtester',
        'smartentry',
        'deepstrike',
        'radar',
        'alerts',
        'backtester',
        'backtesting',
      ];
    }  else {
      const { current } = this.props.app;
      const routeToSubmenuKey = {
        '/news': 'markets',
        '/crypto': 'markets',
        '/overview': 'markets',
        '/chart': 'markets',
        '/flow': 'options',
        '/insights': 'options',
        '/prophet': 'options',
        '/performance': 'options',
        '/chain': 'options',
        '/chain_new': 'options',
        '/scanner': 'stock',
        '/chart-setups': 'stock',
        '/insiders': 'stock',
        '/signals': 'trading',
        '/portfolio': 'trading',
        '/watchlists': 'trading',
        '/riskreward': 'ttools',
        '/journal': 'journal',
        '/stock': 'stock',
        '/strategies': 'backtester',
        '/help': 'backtester',
        '/backtester': 'backtester',
        '/smartentry': 'smartentry',
        '/deepstrike': 'deepstrike',
        '/radar': 'radar',
        '/alerts': 'alerts',
      };

      const openSubmenuKey = routeToSubmenuKey[current] || [];
      return [openSubmenuKey]; // Wrapping in an array because 'openKeys' expects an array
    }
  }

  getAncestorKeys = key => {
    const map = {
      sub3: ['sub2'],
    };
    return map[key] || [];
  };

  getMenuItem = ({ singleOption, submenuStyle, submenuColor, collapsed }) => {
    let Mdivider = '';
    if (singleOption.key == 'alerts' && isPre) {
      Mdivider = { marginTop: '20px', borderTop: 'solid 1px rgb(131,83,141)' };
    }
    if (singleOption.key == 'journal') {
      //return;
    }
    if (
      (singleOption.key == 'alerts' && isPre) ||
      singleOption.key != 'alerts'
    ) {
      const { key, label, leftIcon, children } = singleOption;

      if (children) {
        if (collapsed === true) {
          return children.map(child => {
            let linkTo = child.withoutDashboard
              ? `/${child.key}`
              : `/${child.key}`;
            if (
              child.key === 'stock' &&
              localStorage.getItem('lastUsedSymbol')
            ) {
              linkTo = `/stock/${localStorage.getItem('lastUsedSymbol')}`;
            }
            return (
              <Menu.Item
                style={{ ...submenuStyle, justifyContent: 'center' }}
                key={child.key}
                title={<IntlMessages id={child.label} />}
              >
                <Link
                  className={child.leftIcon + child.leftIcon}
                  style={submenuColor}
                  to={linkTo}
                >
                  <span className={child.leftIcon + ' childico ' + 'false'}>
                    {this.getSubItemIcons(child.leftIcon, collapsed)}
                  </span>
                </Link>
              </Menu.Item>
            );
          });
        }
        if (collapsed === false) {
          return (
            <SubMenu
              key={key}
              title={
                <span className="isoMenuHolder" style={submenuColor}>
                  <span className="nav-text groupLabel ">
                    <IntlMessages id={label} />
                  </span>
                </span>
              }
            >
              {children.map(child => {
                let hasPermission =
                  this.props.isRole0 ||
                  this.props.isRole1 ||
                  this.props.isRole2 ||
                  this.props.isRole3;
                if (
                  child.key === 'earnings' ||
                  child.key === 'overview' ||
                  child.key === 'news' ||
                  child.key === 'stock' ||
                  child.key === 'backtester' ||
                  child.key === 'smartentry' ||
                  child.key === 'deepstrike' ||
                  child.key === 'radar' ||
                  child.key === 'alerts'
                ) {
                  hasPermission = true;
                }

                const hasPermissionC = hasPermission ? '' : 'noperm ';
                let linkTo = child.withoutDashboard
                  ? `/${child.key}`
                  : `/${child.key}`;
                if (
                  child.key === 'stock' &&
                  localStorage.getItem('lastUsedSymbol')
                ) {
                  linkTo = `/stock/${localStorage.getItem('lastUsedSymbol')}`;
                }

                return (
                  <Menu.Item
                    style={submenuStyle}
                    key={child.key}
                    title={child.key?.toUpperCase()}
                    onClick={() => {
                      window.location.pathname = linkTo;
                    }}
                  >
                    <span className={hasPermissionC}>
                      <span
                        className={
                          hasPermissionC +
                          ' ' +
                          child.leftIcon +
                          ' childico ' +
                          collapsed
                        }
                      >
                        {this.getSubItemIcons(child.leftIcon, collapsed)}
                      </span>

                      <IntlMessages id={child.label} />
                      {child.key === 'strategies' ? (
                        <span style={newt}>BETA</span>
                      ) : null}
                      {child.key === 'moneyline' ? (
                        <span style={newt}>NEW</span>
                      ) : null}
                      {child.key === 'trade' ? (
                        <span style={newt}>NEW</span>
                      ) : null}
                      {child.key === 'signals' ? (
                        <span style={newt}>BETA</span>
                      ) : null}
                      {child.key === 'chain' ? (
                        <span style={newt}>NEW</span>
                      ) : null}

                      {child.key === 'zdte' ? (
                        <span style={newt}>BETA</span>
                      ) : null}
                    </span>
                  </Menu.Item>
                );
              })}
            </SubMenu>
          );
        }
      }
      return (
        <Menu.Item
          key={key}
          style={Mdivider}
          // defaultOpenKeys={['stock' , 'option']}
        >
          <Link to={`/${key}`}>
            <span className="isoMenuHolder" style={submenuColor}>
              <i className={leftIcon} />
              <span className="nav-text">
                <IntlMessages id={label} />
                {key === 'blabla' ? (
                  <span style={newt}>NEW</span>
                ) : (
                  <span></span>
                )}
              </span>
            </span>
          </Link>
        </Menu.Item>
      );
    }
  };

  render() {
    const {
      app,
      customizedTheme,
      height,
      isTrial,
      isRole0,
      isRole1,
      isRole2,
      isRole3,
    } = this.props;

    const collapsed = clone(app.collapsed) && !clone(app.openDrawer);
    //window.colps = collapsed;
    const { openDrawer } = app;
    const mode = 'inline';
    const onMouseEnter = event => {
      event.preventDefault();
      if (openDrawer === false) {
        toggleOpenDrawer();
      }
      return;
    };
    const onMouseLeave = e => {
      e.preventDefault();
      if (openDrawer === true) {
        toggleOpenDrawer();
      }
      return;
    };
    const styling = {
      backgroundColor: customizedTheme.backgroundColor,
    };
    const submenuStyle = {
      backgroundColor: 'rgb(30 30 45)',
      color: customizedTheme.textColor,
      margin: '3px 0',
      display: 'flex',
      justifyContent: 'start',
    };
    const submenuColor = {
      color: customizedTheme.textColor,
    };

    return (
      <SidebarWrapper>
        <Sider
          trigger={null}
          collapsible={true}
          collapsed={collapsed}
          width={180}
          className="isomorphicSidebar"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          style={styling}
        >
          <Logo collapsed={collapsed} toggleCollapsed={this.handleClick} />
          <Scrollbars
            //style={{ height: height - 70 }}
            className="sidebarScroll"
          >
            <Menu
            
              defaultOpenKeys={this.getOpenedSub()}
              onClick={this.handleMenuClick}
              theme="dark"
              className="isoDashboardMenu"
              mode={mode}
              onOpenChange={this.onOpenChange}
              selectedKeys={app.current}
            >
              {MenuOptions.map(singleOption =>
                this.getMenuItem({
                  submenuStyle,
                  submenuColor,
                  singleOption,
                  collapsed,
                })
              )}

              {isTrial && !collapsed && (
                <div
                  style={{
                    width: '85%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    lineHeight: 1,
                    margin: '20px 0',
                  }}
                >
                  <a
                    href="https://tradeui.com/account"
                    style={{
                      border: 'solid 1px rgb(114 82 230)',
                    }}
                  >
                    <button
                      className="update"
                      style={{
                        marginLeft: '2px',
                        padding: '3px 3px',
                        display: 'flex',
                        color: 'rgb(114 82 230)',
                        alignItems: 'center',
                      }}
                    >
                      UPGRADE
                    </button>
                  </a>

                  <div
                    style={{
                      marginTop: '15px',
                      textAlign: 'center',
                    }}
                  >
                    {daysTillExpiry >= 0 ? (
                      <>
                        <p>
                          Trial ends in:
                          <br />{' '}
                          <span style={{ fontWeight: 'bold', color: 'white' }}>
                            {daysTillExpiry}
                          </span>{' '}
                          Days
                        </p>
                      </>
                    ) : (
                      <>
                        <p>
                          Trial ended
                          <br />{' '}
                          <span style={{ fontWeight: 'bold', color: 'white' }}>
                            {Math.abs(daysTillExpiry)}
                          </span>{' '}
                          Days ago
                        </p>
                      </>
                    )}
                  </div>
                </div>
              )}
              <div style={{ position: 'fixed', bottom: '5px' }}></div>
            </Menu>
          </Scrollbars>
        </Sider>
      </SidebarWrapper>
    );
  }
}
export default connect(
  state => ({
    app: state.App,
    customizedTheme: state.ThemeSwitcher.sidebarTheme,
    height: state.App.height,
    collapsed: state.App.collapsed,
    isTrial: state.App.isTrial,
    isRole3: state.App.isRole3,
    isRole0: state.App.isRole0,
    isRole1: state.App.isRole1,
    isRole2: state.App.isRole2,
  }),
  { toggleOpenDrawer, changeOpenKeys, changeCurrent, toggleCollapsed }
)(Sidebar);
