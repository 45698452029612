import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Layout, AutoComplete, notification, message, Badge } from 'antd';
import appActions from '../../redux/app/actions';
import TopbarUser from './topbarUser';
import TopbarWrapper from './topbar.style';
import './topbar.css';
import Tooltip from '@isomorphic/shared/isomorphic/components/uielements/tooltip';
import notifications from '@isomorphic/shared/isomorphic/components/Feedback/Notification';
import Button from '@isomorphic/shared/isomorphic/components/uielements/button'; 
import MessageContent from '../../containers/Feedback/Message/message.style';
import Popconfirms from '@isomorphic/shared/isomorphic/components/Feedback/Popconfirm';
import PopconfirmWrapper from '../Feedback/Popconfirm/popconfirm.style';
import QuickNotifications from './QuickNotifications';
import ClockWidget from './Clock_widget/ClockWidget';
import ChatFloat from '../../customApp/containers/widgets/ChatFloat';
import { updateServiceWorker } from '../../customApp/containers/helper';
import TopbarNotification from './topbarNotification';
import axios from 'axios';
import MarketPosition from './MarketPosition';

// import SocialW from './SocialW';
// import Logo from '@isomorphic/shared/isomorphic/components/utility/logo';

// import 'intro.js/introjs.css';
// import { Steps } from 'intro.js-react';
//ModalContent,
//import ModalStyle from '../../containers/Feedback/Modal/modal.style';
// import SocketConnected from './Clock_widget/SocketConnected';
// import MarketOpenCloseStatus from './Clock_widget/MarketOpenCloseStatus';
//import Alert from '@isomorphic/shared/isomorphic/components/Feedback/Alert';
//import { threadId } from 'worker_threads';
// import TopbarMessage from './topbarMessage';
// import * as tui from '../../customApp/containers/helper';
// import '../../customApp/containers/css/tui.css';
//import TopbarSearch from "./topbarSearch";
//import TopbarAddtoCart from "./topbarAddtoCart";
//, Hints
//const stepsEnabled = true;
const initialStep = 0;
const steps = [
  {
    element: '.flowflow',
    intro: "<b>👋Flow Product Tour</b><br>Let's get started",
    position: 'right',
    // tooltipClass: 'myTooltipClass',
    // highlightClass: 'myHighlightClass',
  },
  {
    element: '#Pane1',
    intro:
      '<b>🔎Advanced Filters</b><br>Used to eliminate the noise <br> make sure you click on the apply icon after updating filters',
    position: 'right',
    tooltipClass: 'myTooltipClass',
    highlightClass: 'myHighlightClass',
  },
  {
    element: '#Pane2',
    intro:
      '<b>Chart Setups</b><br>Scans symbols with sweeps for technical daily setups for potential swing trades.',
    position: 'right',
    tooltipClass: 'myTooltipClass',
    highlightClass: 'myHighlightClass',
  },
  {
    element: '#Pane3',
    intro:
      '<b>🔥Unusual Flow</b><br>Displays filtered results of Sweeps with unusual volume over open interest ',
    position: 'right',
    tooltipClass: 'myTooltipClass',
    highlightClass: 'myHighlightClass',
  },
  {
    element: '.oui1',
    intro:
      '<b>🏆Top Flow</b><br>This unique feature shows CALLS/PUTS with green when # CALLS are twice # of PUTS and vice versa. Purple is a mixed signal.',
  },
  {
    element: '.flowtext',
    intro:
      '<b>📋Flow Table</b><br>Shows the option flow results, Realtime mode is real time and work during market hours while PRESETS and HISTORICAL are used to fetch previous dates',
  },
  {
    element: '.Tmode',
    intro:
      '<b>🎚️Flow Toolbar</b><br>Hover over each icon will display its utility, you can customize your columns,enable extra flag indicators or tweet the table screenshot with 1 click ...',
  },
  {
    element: '.ion-arrow-expand',
    intro: 'Expand or collapse full screen mode',
  },
  {
    element: '.ion-ios-browsers-outline',
    intro: 'Toggle the advanced filter, Unusual Flow and Flow Setups row',
  },
  {
    element: '.ion-bonfire',
    intro: 'Toggle Gaps and Socially trending tickers widget',
  },
  {
    element: '.reachout',
    intro:
      '<b>Reach out🤙</b><br>We are here 7 days a week email us support@tradeui.com or join discord TradeUI.com/invite',
  },
];

const Popconfirm = props => (
  <PopconfirmWrapper>
    <Popconfirms {...props} />
  </PopconfirmWrapper>
);
// const isoModal = ModalStyle(Modals);

const { Header } = Layout;
const { toggleCollapsed, toggleDrawer, changeTheme } = appActions;
// const idT = localStorage.getItem('id_token');
const close = () => {};
function confirm(e) {
  message.success(
    'Risk to long side increases when the market is UP more than 5 days in a row'
  );
}

function cancel(e) {
  message.error('Click on No');
}
const btnClick = function() {
  let fn = () => {};
  let ver = localStorage.getItem('tui_u');
  localStorage.setItem('tui_v', ver);
  localStorage.removeItem('tui_u');
  caches.keys().then(function(names) {
    for (let name of names) caches.delete(name);
  });

  setTimeout(() => {
    updateServiceWorker();
    window.location.reload(true);
  }, 100);
};
const btn = (
  <Button className="upgradenow" type="primary" size="small" onClick={btnClick}>
    Upgrade Now
  </Button>
);

window.UpdateNotifs = ver => {
  if (window.isUpdateNotificationShown) return;
  const args = {
    message: `🔥 v${ver} Update Available`,
    description:
      'New update will be installed when all TradeUI tabs are closed.',
    duration: 0,
    onClose: close,
    btn,
  };
  notifications.open(args);
};

class Topbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      watchlistItems: { hov: [], n52wh: [], n52wl: [] },
      DarkMode: 'yes',
      TableOnly: 'yes',
      SocialW: '1',
      stylePath: '',
      initialStep: 0,
      fut: {},
      news: [],
      date: new Date(),
      searchResults: [],
      marketStatus: null,

      //stepsEnabled: false,
      //steps: steps,
    };
    this.toggleChat = this.toggleChat.bind(this);
  }
  handleKeyDown = event => {
    if (event.key === 'Enter') {
      const { value } = this.state;
      this.props.history.push(`/stock/${value}`);
    }
  };
  timeto() {
    var start = new Date();
    start.setHours(23, 0, 0); // 11pm

    function pad(num) {
      return ('0' + parseInt(num)).substr(-2);
    }

    function tick() {
      var now = new Date();
      if (now > start) {
        // too late, go to tomorrow
        start.setDate(start.getDate() + 1);
      }
      var remain = (start - now) / 1000;
      var hh = pad((remain / 60 / 60) % 60);
      var mm = pad((remain / 60) % 60);
      var ss = pad(remain % 60);
      document.getElementById('timeto').innerHTML = hh + ':' + mm + ':' + ss;
      setTimeout(tick, 1000);
    }

    document.addEventListener('DOMContentLoaded', tick);
  }

  componentWillUnmount() {
    // clearInterval(this.timerID);
  }
  video_intro(el) {
    console.log(el);
    document.onkeydown = function(evt) {
      evt = evt || window.event;
      if (evt.keyCode == 27) {
        document.getElementById('overlay2').style.visibility = 'hidden';
      }
    };
    el.style.visibility =
      el.style.visibility == 'visible' ? 'hidden' : 'visible';
  }
  switchMarket(v) {
    if (v.target && v.target.value) {
      document.getElementById('arrS').innerHTML = v.target.value;
    }
  }
  marketState() {}

  fetchMarketStatus = () => {
    const today = new Date().toISOString().split('T')[0];
    const marketStatusKey = 'marketStatusShownDate';

    axios
      .get('https://ab.tradeui.com/api/marketopen.php', {
        responseType: 'text',
      })
      .then(response => {
        if (
          response.data &&
          response.data !==
            'No market closure or early closing event found for today.'
        ) {
          const lastShownDate = localStorage.getItem(marketStatusKey);

          if (lastShownDate !== today) {
            this.setState({ marketStatus: response.data });
            notification.info({
              message: 'Market Status',
              description: `${response.data}`,
              duration: 0,
              key: 'marketNotification',
              onClose: () => {
                localStorage.setItem(marketStatusKey, today);
              },
            });
          }
        }
      })
      .catch(error => {
        console.error('Error fetching market status:', error.message);
      });
  };

  componentDidMount() {
    this.fetchMarketStatus();

    const inputElement = document.querySelector(
      '.ant-input.ant-select-search__field'
    );
    inputElement.setAttribute('placeholder', 'Enter Symbol');

    if (localStorage.getItem('SocialW') == null) {
      localStorage.setItem('SocialW', '1');
      document.getElementById('TUIc').style.paddingTop = '5px';
    }
    if (
      localStorage.getItem('SocialW') &&
      localStorage.getItem('SocialW') === '1'
    ) {
      this.setState({ SocialW: '1' });
      if (document.getElementById('SocialW'))
        document.getElementById('SocialW').style.display = 'block';
      localStorage.setItem('SocialW', '1');
      document.getElementById('TUIc').style.paddingTop = '5px';
    } else if (
      localStorage.getItem('SocialW') === '0' &&
      document.getElementById('SocialW')
    ) {
      this.setState({ SocialW: '0' });

      if (document.getElementById('SocialW'))
        document.getElementById('TUIc').style.paddingTop = '45px';

      document.getElementById('SocialW').style.display = 'none';
      localStorage.setItem('SocialW', '0');
    }
    if (
      localStorage.getItem('TableOnly') &&
      localStorage.getItem('TableOnly') === 'yes'
    ) {
      this.setState({ TableOnly: 'yes' });
      if (document.getElementById('frow'))
        document.getElementById('frow').style.display = 'none';
      localStorage.setItem('TableOnly', 'yes');
    } else if (
      localStorage.getItem('TableOnly') === 'no' &&
      document.getElementById('TableOnly')
    ) {
      this.setState({ TableOnly: 'no' });
      if (document.getElementById('frow'))
        document.getElementById('frow').style.display = 'block';
      localStorage.setItem('TableOnly', 'no');
    }

    if (
      (localStorage.getItem('darkMode') &&
        localStorage.getItem('darkMode') === 'yes') ||
      localStorage.getItem('darkMode') == null
    ) {
      this.setState({ DarkMode: 'yes' }, function() {
        var sheet = document.createElement('link');
        sheet.rel = 'stylesheet';
        sheet.href = '/css/tui-dark.css';
        sheet.type = 'text/css';
        sheet.id = 'darkMode';
        document.head.appendChild(sheet);
      });
    } else if (
      localStorage.getItem('darkMode') === 'no' &&
      document.getElementById('darkMode')
    ) {
      document
        .getElementById('darkMode')
        .parentNode.removeChild(document.getElementById('darkMode'));
    }
  }
  toggleTableOnly() {
    if (this.state.TableOnly === 'yes') {
      this.setState({ TableOnly: 'no' });
      document.getElementById('frow').style.display = 'none';
      localStorage.setItem('TableOnly', 'yes');
    } else {
      this.setState({ TableOnly: 'yes' });
      document.getElementById('frow').style.display = 'block';
      localStorage.setItem('TableOnly', 'no');
    }
  }
  toggleSocial() {
    this.props.toggleDrawerSocial();
  }
  toggleChat() {
    this.props.toggleDrawerChat();
  }

  toggleMode() {
    var sheet = document.createElement('link');
    sheet.rel = 'stylesheet';
    sheet.href = '/css/tui-dark.css';
    sheet.type = 'text/css';
    sheet.id = 'darkMode';

    if (this.state.DarkMode === 'yes' && document.getElementById('darkMode')) {
      this.setState({ DarkMode: 'no', stylePath: '' });
      document
        .getElementById('darkMode')
        .parentNode.removeChild(document.getElementById('darkMode'));
      localStorage.setItem('darkMode', 'no');
    } else {
      this.setState({ DarkMode: 'yes', stylePath: '/css/tui-dark.css' });
      document.head.appendChild(sheet);
      //console.log(this.state.DarkMode);
      localStorage.setItem('darkMode', 'yes');
    }
    this.props.changeTheme();
  }

  toggleFSMode(event) {
    var element = document.body;

    if (event instanceof HTMLElement) {
      element = event;
    }

    var isFullscreen =
      document.webkitIsFullScreen || document.mozFullScreen || false;

    element.requestFullScreen =
      element.requestFullScreen ||
      element.webkitRequestFullScreen ||
      element.mozRequestFullScreen ||
      function() {
        return false;
      };
    document.cancelFullScreen =
      document.cancelFullScreen ||
      document.webkitCancelFullScreen ||
      document.mozCancelFullScreen ||
      function() {
        return false;
      };

    isFullscreen ? document.cancelFullScreen() : element.requestFullScreen();
  }

  tick() {
    this.setState({
      date: new Date(),
    });
  }
  onExit = () => {
    //this.setState(() => ({ stepsEnabled: false }));
  };
  // popout() {
  //   window.open(
  //     '/optionflow?idt=' + idT,
  //     //'https://tradeui.com/channels/tradeui?idt=' + idT,
  //     'popup',
  //     'width=800,height=600,top=200,left=500,location=0,status=0,menubar=0'
  //   );
  //   return false;
  // }
  // popoutsignals() {
  //   window.open(
  //     '/signals?idt=' + idT,
  //     'popup',
  //     'width=800,height=600,top=200,left=500,location=0,status=0,menubar=0'
  //   );
  //   return false;
  // }

  onClose = function(e) {};

  searchStocks = searchText => {
    this.setState({ value: searchText });

    if (!searchText) {
      this.setState({ searchResults: [] });
      return;
    }

    axios
      .get(
        `https://ab.tradeui.com/api/tickers2.php?q=${encodeURIComponent(
          searchText
        )}`
      )
      .then(response => {
        const searchResults = response.data.map(stock => ({
          key: stock.symbol || 'N/A',
          value: stock.symbol || 'N/A',
          label:
            stock.symbol && stock.name
              ? `${stock.symbol} - ${stock.name}`
              : 'N/A', // Provide a placeholder if 'symbol' or 'name' is missing
        }));
        this.setState({ searchResults });
      })
      .catch(error => console.error('Failed to fetch stocks:', error));
  };
  handleSelect = value => {
    this.props.history.push(`/stock/${value}`);
    // Optional: Clear the input after selection
    // this.setState({ value: '' });
    // Or, if you want to keep the selected value in the input:
    // this.setState({ value, searchResults: [] });
  };

  render() {
    const { searchResults } = this.state;
    const { marketStatus } = this.state;

    let toggleSocialIco = '',
      toggleChatIco = '',
      toggleOnly = '',
      MarketStretch = '',
      youtubeV = '',
      guideMe = '',
      howtotour = '';

    toggleSocialIco = (
      <li
        style={{ marginLeft: '4px', marginRight: '4px' }}
        onClick={() => this.toggleSocial()}
        className="isoContrast"
      >
        <Tooltip title={`Worth Watching`}>
          <i className="ion-bonfire"></i>
        </Tooltip>
      </li>
    );
    toggleChatIco = (
      <li
        style={{ marginLeft: '4px', marginRight: '12px' }}
        onClick={() => this.toggleChat()}
        className="isoContrast"
      >
        <Tooltip title={`Chat`}>
          <i className="ion-chatbox-working"></i>
        </Tooltip>
      </li>
    );

    const toggleNews = (
      <li
        onClick={() => {
          if (this.props.drawerState) {
            if (!this.props.current.includes('news')) {
              this.props.drawerState.handleToggleDrawer();
              this.props.toggleDrawer();
              if (!this.props.collapsed) {
                toggleCollapsed();
              }
            }
          }
        }}
        className="isoContrast noMob"
      >
        {/* <a className="stylebutton_color" style={{ height: '51px' }}>
          UPGRADE{' '}
        </a> */}
        <Tooltip title={`News Sidebar`}>
          <a
            className="stylebutton_color"
            style={{ height: '51px', position: 'relative', top: '2px' }}
          >
            <svg
              width="21"
              height="19"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="mh-50px"
            >
              <path
                opacity="0.3"
                d="M17 10H11C10.4 10 10 9.6 10 9V8C10 7.4 10.4 7 11 7H17C17.6 7 18 7.4 18 8V9C18 9.6 17.6 10 17 10ZM22 4V3C22 2.4 21.6 2 21 2H11C10.4 2 10 2.4 10 3V4C10 4.6 10.4 5 11 5H21C21.6 5 22 4.6 22 4ZM22 15V14C22 13.4 21.6 13 21 13H11C10.4 13 10 13.4 10 14V15C10 15.6 10.4 16 11 16H21C21.6 16 22 15.6 22 15ZM18 20V19C18 18.4 17.6 18 17 18H11C10.4 18 10 18.4 10 19V20C10 20.6 10.4 21 11 21H17C17.6 21 18 20.6 18 20Z"
                fill="#ffffff"
                className="arrfill"
              ></path>
              <path
                d="M8 5C8 6.7 6.7 8 5 8C3.3 8 2 6.7 2 5C2 3.3 3.3 2 5 2C6.7 2 8 3.3 8 5ZM5 4C4.4 4 4 4.4 4 5C4 5.6 4.4 6 5 6C5.6 6 6 5.6 6 5C6 4.4 5.6 4 5 4ZM8 16C8 17.7 6.7 19 5 19C3.3 19 2 17.7 2 16C2 14.3 3.3 13 5 13C6.7 13 8 14.3 8 16ZM5 15C4.4 15 4 15.4 4 16C4 16.6 4.4 17 5 17C5.6 17 6 16.6 6 16C6 15.4 5.6 15 5 15Z"
                fill="#ffffff"
                className="arrfill"
              ></path>
            </svg>
          </a>
        </Tooltip>
      </li>
    );

    if (window.location.href.indexOf('flow') > -1) {
      howtotour = (
        <li className="reachout isoContrast noMob">
          <Tooltip title={`Start How to Tour!`}>
            <svg
              style={{ marginTop: '1px' }}
              width="23"
              height="23"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="mh-50px "
              onClick={() => this.setState(() => ({ stepsEnabled: true }))}
            >
              <rect
                opacity="0.4"
                x="2"
                y="2"
                width="20"
                height="20"
                rx="10"
                fill="#7251e6"
              ></rect>
              <path
                d="M11.276 13.654C11.276 13.2713 11.3367 12.9447 11.458 12.674C11.5887 12.394 11.738 12.1653 11.906 11.988C12.0833 11.8107 12.3167 11.61 12.606 11.386C12.942 11.1247 13.1893 10.896 13.348 10.7C13.5067 10.4947 13.586 10.2427 13.586 9.944C13.586 9.636 13.4833 9.356 13.278 9.104C13.082 8.84267 12.69 8.712 12.102 8.712C11.486 8.712 11.066 8.866 10.842 9.174C10.6273 9.482 10.52 9.82267 10.52 10.196L10.534 10.574H8.826C8.78867 10.3967 8.77 10.2333 8.77 10.084C8.77 9.552 8.90067 9.07133 9.162 8.642C9.42333 8.20333 9.81067 7.858 10.324 7.606C10.8467 7.354 11.4813 7.228 12.228 7.228C13.1987 7.228 13.9687 7.44733 14.538 7.886C15.1073 8.31533 15.392 8.92667 15.392 9.72C15.392 10.168 15.322 10.5507 15.182 10.868C15.042 11.1853 14.874 11.442 14.678 11.638C14.482 11.834 14.2253 12.0533 13.908 12.296C13.544 12.576 13.2733 12.8233 13.096 13.038C12.928 13.2527 12.844 13.528 12.844 13.864V14.326H11.276V13.654ZM11.192 15.222H12.928V17H11.192V15.222Z"
                fill="currentColor"
              ></path>
            </svg>
          </Tooltip>
        </li>
      );
    }
    if (window.location.href.indexOf('flow') > -1) {
      setTimeout(() => {
        if (document.getElementById('marketState')) {
          document.getElementById('marketState').style.display = 'block';
        }
      }, 100);
      MarketStretch = <div> </div>;
      guideMe = (
        <Tooltip
          title={`Feeling overwhelmed or have questions? Join our discord TradeUI.com/invite and ask the team and community.`}
        >
          <i className="ion-help"></i>
        </Tooltip>
      );

      youtubeV = (
        <Tooltip title={`Option Flow Overview Video`}>
          <svg
            onClick={() =>
              this.video_intro(document.getElementById('overlay2'))
            }
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="mh-50px"
          >
            <path
              d="M21 6.30005C20.5 5.30005 19.9 5.19998 18.7 5.09998C17.5 4.99998 14.5 5 11.9 5C9.29999 5 6.29998 4.99998 5.09998 5.09998C3.89998 5.19998 3.29999 5.30005 2.79999 6.30005C2.19999 7.30005 2 8.90002 2 11.9C2 14.8 2.29999 16.5 2.79999 17.5C3.29999 18.5 3.89998 18.6001 5.09998 18.7001C6.29998 18.8001 9.29999 18.8 11.9 18.8C14.5 18.8 17.5 18.8001 18.7 18.7001C19.9 18.6001 20.5 18.4 21 17.5C21.6 16.5 21.8 14.9 21.8 11.9C21.8 9.00002 21.5 7.30005 21 6.30005ZM9.89999 15.7001V8.20007L14.5 11C15.3 11.5 15.3 12.5 14.5 13L9.89999 15.7001Z"
              fill="currentColor"
            ></path>
          </svg>
          {/* <i
            onClick={() =>
              this.video_intro(document.getElementById('overlay2'))
            }
            className="ion-social-youtube-outline fleft"
          ></i> */}
        </Tooltip>
      );
      toggleOnly = (
        <li onClick={() => this.toggleTableOnly()} className="isoContrast">
          <Tooltip title={`Toggle Compact Mode`}>
            <i className="ion-ios-browsers-outline"></i>
          </Tooltip>
        </li>
      );
    }
    const { toggleCollapsed, customizedTheme, locale } = this.props;
    const collapsed = this.props.collapsed && !this.props.openDrawer;
    const drawerIsOpen = JSON.parse(localStorage.getItem('DRAWER_STATE_XYZ'));
    const styling = {
      background: customizedTheme.backgroundColor,
      position: 'fixed',
      width: '100%',
      height: 50,
    };
    const fdown = (
      <span>
        <i className="ion-arrow-down-a firered"></i>{' '}
      </span>
    );
    const fup = (
      <span>
        <i className="ion-arrow-up-a firegreen"></i>{' '}
      </span>
    );

    var hideThis = '';

    if (window.location.search.includes('nosb')) {
      hideThis = 'hideThis';
    }

    return (
      <TopbarWrapper className={hideThis}>
        {/* <Steps
          enabled={this.state.stepsEnabled}
          steps={steps}
          initialStep={initialStep}
          onExit={this.onExit}
        /> */}
        <QuickNotifications />
        <Header
          style={{ ...styling, paddingLeft: '' }}
          className={
            collapsed ? 'isomorphicTopbar collapsed' : 'isomorphicTopbar'
          }
        >
          <div className="isoLeft">
            {/* { <Logo />} */}
            {
              <>
                <button
                  className={
                    collapsed
                      ? 'exped stylebutton_color triggerBtn menuCollapsed'
                      : 'triggerBtn menuOpen'
                  }
                  style={{ color: customizedTheme.textColor }}
                  onClick={() => {
                    if (!drawerIsOpen) {
                      toggleCollapsed();
                    } else {
                      message.info(
                        <MessageContent>
                          Expanding Menu Is Disabled When News Sidebar Is Open!
                        </MessageContent>,
                        3
                      );
                    }
                  }}
                >
                  <svg
                    style={{ backgroundColor: '#1e1e2d' }}
                    className={
                      collapsed ? 'stylebutton_color collaps' : 'nocollaps'
                    }
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      className="arrfill"
                      opacity="0.5"
                      d="M14.2657 11.4343L18.45 7.25C18.8642 6.83579 18.8642 6.16421 18.45 5.75C18.0358 5.33579 17.3642 5.33579 16.95 5.75L11.4071 11.2929C11.0166 11.6834 11.0166 12.3166 11.4071 12.7071L16.95 18.25C17.3642 18.6642 18.0358 18.6642 18.45 18.25C18.8642 17.8358 18.8642 17.1642 18.45 16.75L14.2657 12.5657C13.9533 12.2533 13.9533 11.7467 14.2657 11.4343Z"
                      fill="#ffffff"
                    ></path>
                    <path
                      className="arrfill"
                      d="M8.2657 11.4343L12.45 7.25C12.8642 6.83579 12.8642 6.16421 12.45 5.75C12.0358 5.33579 11.3642 5.33579 10.95 5.75L5.40712 11.2929C5.01659 11.6834 5.01659 12.3166 5.40712 12.7071L10.95 18.25C11.3642 18.6642 12.0358 18.6642 12.45 18.25C12.8642 17.8358 12.8642 17.1642 12.45 16.75L8.2657 12.5657C7.95328 12.2533 7.95328 11.7467 8.2657 11.4343Z"
                      fill="#ffffff"
                    ></path>
                  </svg>
                </button>
              </>
            }

            
            <div id="marketState">
              <span id="fngindex" className="badge">
                {' '}
              </span> 
              <span className="timeto heatfuture">
              <MarketPosition />
                <select
                  onChange={this.switchMarket}
                  id="mstretch"
                  style={{ fontWeight: 'bold', fontSize: '10px' }}
                >
                  <option value="ES">/ES</option>
                  <option value="NQ">/NQ</option>
                  <option value="YM">/YM</option>
                  <option value="GC">/GC</option>
                  <option value="CL">/CL</option>
                  <option value="BTC">BTC</option>
                </select>
                <span id="arrS"></span>
                <span onClick={() => this.marketState()}>
                  <Popconfirm
                    placement="bottomLeft"
                    title={
                      'Market Stretch shows a red arrow if the market was down, green for up on the daily timeframe for the past 5 days.'
                    }
                    onConfirm={confirm}
                    okText="Got it!"
                    cancelText="Close"
                  >
                    <span id="fupdown"></span> 
                  </Popconfirm>
                </span>
              </span>
            </div> 
            <ClockWidget /> 
          </div>

          <div className="isoMiddle">
            <AutoComplete
              onKeyDown={this.handleKeyDown}
              className="custom-auto-complete"
              style={{
                width: 170,
                marginTop: '10px',
                color: 'white',
                fontSize: '1.15em',
              }}
              filterOption={false}
              value={this.state.value}
              dropdownMatchSelectWidth={false}
              dropdownStyle={{ width: 400 }}
              dataSource={this.state.searchResults.map(item => ({
                value: item.value,
                text: item.label,
              }))} 
              onSearch={this.searchStocks} 
              onSelect={value => {
                this.setState({ value }, () => {
                  this.props.history.push(`/stock/${value}`);
                });
              }}
            />
          </div>
          <ul className="isoRight">
            <link
              rel="stylesheet"
              type="text/css"
              href={this.state.stylePath}
            />
            {/* {toggleNews} */} 
            <li
              onClick={() => this.toggleFSMode()}
              className="isoContrast noMob"
            >
              <Tooltip title={`Toggle Fullscreen mode`}>
                <i
                  className="ion-arrow-expand"
                  style={{ marginLeft: '4px' }}
                ></i>
              </Tooltip>
            </li>
            {/* <li
              onClick={() => this.setState({ selectedItem: 'notification' })}
              className="isoNotify"
            >
              <TopbarNotification locale={locale} />
            </li> */}
            {/* {toggleChatIco} */}
            {toggleOnly}
            {toggleSocialIco}

            <li
              onClick={() => this.setState({ selectedItem: 'user' })}
              className="isoUser"
            >
              <TopbarUser locale={locale} />
            </li>
            {/* <li
              onClick={() => this.setState({ selectedItem: 'message' })}
              className="isoMsg"
            >
              <TopbarMessage locale={locale} />
            </li> */}
            {/* <li
              onClick={() => this.setState({ selectedItem: "addToCart" })}
              className="isoCart"
            >
              <TopbarAddtoCart url={url} locale={locale} />
            </li> */}
          </ul>
        </Header>
        {/* <ChatFloat onClick={this.toggleChat} /> */}
      </TopbarWrapper>
    );
  }
}

export default withRouter(
  connect(
    state => ({
      ...state.App,
      locale: state.LanguageSwitcher.language.locale,
      customizedTheme: state.ThemeSwitcher.topbarTheme,
      isRole3: state.App.isRole3,
      isRole2: state.App.isRole2,
      isRole1: state.App.isRole1,
      isRole0: state.App.isRole0,
    }),
    { toggleCollapsed, toggleDrawer, changeTheme }
  )(Topbar)
);
